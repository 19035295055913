<template>
  <div class="overflow-hidden scroll-smooth">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
