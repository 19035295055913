<template>
  <div class="bg-black min-h-screen flex flex-col pt-6 md:pt-12">
    <div class="flex flex-col items-center">
      <div>
        <p id="work"
           class=" animate-fade font-clash h-full text-4xl md:text-6xl lg:text-7xl select-none font-extrabold text-transparent text-white pt-10">
          Mon travail
        </p>
      </div>
    </div>
    <div class="h-[80vh] pt-8 px-2">
      <!-- Desktop -->
      <div class="example-2 proprio card hidden md:block">
        <div class="wrapper rounded-2xl cursor-pointer" onclick="window.open('https://www.proprio-access.fr', '_blank');">
          <div class="data">
            <div class="content">
                <span class="author"><span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
  ProprioAccess
</span></span>
              <h1 class="title"><a href="#">Back office.</a></h1>
              <p class="text">Il s'agit d'un mini ERP destiné à la gestion de mandats et à l'envoi de documents pour les
                employés de l'agence.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="example-2 tns card hidden md:block">
        <div class="wrapper rounded-2xl cursor-pointer" onclick="window.open('https://www.tnservices.fr', '_blank');">
          <div class="data">
            <div class="content">
                <span class="author"><span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-blue-600 bg-blue-200 last:mr-0 mr-1">
  Telecom Nancy Services
</span></span>
              <h1 class="title"><a href="#">Site vitrine.</a></h1>
              <p class="text">Il s'agit d'un site vitrine pour la société Télécom Nancy Services.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="example-2 toureasy card mt-5 hidden md:block">
        <div class="wrapper rounded-2xl cursor-pointer" onclick="window.open('https://www.toureasy.fr', '_blank');">
          <h2 id="toureasy" style="line-height: normal"
              class="font-clash mx-auto flex items-center box-title animate-fade h-full w-fit text-2xl md:text-8xl mb-4 select-none font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-orange-500 transition-opacity duration-500">
            Toureasy
          </h2>
          <div class="data">
            <div class="content">
                <span class="author"><span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 last:mr-0 mr-1">
  Toureasy
</span></span>
              <h1 class="title">Projet universitaire.</h1>
              <p class="text">Toureasy est un projet universitaire innovant qui permet de localiser des monuments sur une carte et de les partager avec d'autres utilisateurs.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="example-2 download-yt card mt-5 hidden md:block">
        <div class="wrapper rounded-2xl cursor-pointer" onclick="window.open('https://www.silviobrancati.fr/download-yt/', '_blank');">
          <div class="data">
            <div class="content">
                <span class="author"><span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 last:mr-0 mr-1">
  Youtube downloader MP3
</span></span>
              <h1 class="title">Projet personnel.</h1>
              <p class="text">Il s'agit d'un site permettant de télécharger le fichier audio d'une vidéo YouTube.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile -->
      <div class="h-[80vh] px-2 grid-cols-1 grid gap-y-4 md:hidden">
        <div class="bg-[#1a1a1a] rounded-2xl proprio-mobile">
            <h2 class="text-white text-lg font-bold p-4"><span class="author"><span
                class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
  ProprioAccess
</span></span></h2>
            <p class="text-white p-4 text-sm w-1/2">ERP destiné à la gestion de mandats et à l'envoi de documents.</p>
        </div>
        <div class="bg-[#1a1a1a] rounded-2xl tns-mobile">
          <h2 class="text-white text-lg font-bold p-4"><span class="author"><span
              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-blue-600 bg-blue-200 last:mr-0 mr-1">
  Télécom Nancy Services
</span></span></h2>
          <p class="text-white p-4 text-sm w-1/2">Site vitrine pour la société Télécom Nancy Services.</p>
        </div>
        <div class="bg-[#1a1a1a] rounded-2xl toureasy-mobile">
          <h2 class="text-white text-lg font-bold p-4"><span class="author"><span
              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 last:mr-0 mr-1">
  Toureasy
</span></span></h2>
          <p class="text-white p-4 text-sm w-1/2">Projet universitaire permettant de localiser des monuments et de les partager.</p>
        </div>
        <div class="bg-[#1a1a1a] rounded-2xl download-yt-mobile">
          <h2 class="text-white text-lg font-bold p-4"><span class="author"><span
              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-red-600 bg-red-200 last:mr-0 mr-1">
  Youtube downloader
</span></span></h2>
          <p class="text-white p-4 text-sm w-1/2">Site permettant de télécharger le fichier audio d'une vidéo YouTube.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkPage",
  methods: {
    redirect(url) {
      window.open(url, '_blank');
    },
    changeSelected() {
      const numProjects = this.project_data.length;
      this.selected = (this.selected + 1) % numProjects;
      this.animateScale();
    },
    animateScale() {
      const boxes = document.querySelectorAll('.boxs');
      boxes.forEach((box, index) => {
        if (index === this.selected) {
          box.classList.add('scale-[0.97]');
        } else {
          box.classList.remove('scale-[0.97]');
        }
      });
    }
  },
  mounted() {
    // Définir l'intervalle de changement de la sélection
    setInterval(() => {
      this.changeSelected();
    }, 10000);
  },
  data() {
    return {
      selected: 0,
      project_data: [
        {
          description: "Le back office de ProprioAccess est un projet que j'ai réalisé pour une agence immobilière. Il s'agit d'un mini ERP destiné à la gestion de mandats et à l'envoi de documents pour les employés de l'agence. Ce back office permet une meilleure organisation des données liées aux mandats, des fichiers liés aux biens et aux documents administratifs, ainsi qu'une gestion simplifiée des échanges avec les clients.",
          title: "ProprioAccess",
          url: 'https://proprio-access.fr',
          image: '/assets/images/capture/capture_proprioaccess.png'
        },
        {
          description: "Le site web de Telecom Nancy Services a été réalisé pour présenter cette Junior-Entreprise de TELECOM Nancy, une école d’ingénieur habilité par la Commission des Titres d’Ingénieur (CTI).",
          title: "TNS",
          url: 'https://tnservices.fr',
          image: '/assets/images/capture/capture_tns.png'
        },
        {
          description: "Toureasy est un projet universitaire innovant qui permet de localiser des monuments sur une carte et de les partager avec d'autres utilisateurs. Conçu pour être contributif, ce site web permet à tout le monde de prendre en photo un monument et de se localiser afin de l'ajouter sur la carte. Cette initiative a pour but de partager la richesse du patrimoine historique et culturel à travers le monde et de permettre à chacun de découvrir de nouveaux lieux et monuments.",
          title: "Toureasy",
          url: 'https://toureasy.fr',
          image: '/assets/images/capture/capture_toureasy.png'
        }
      ],
      activeIndex: 0,
      images: [
        "https://cdn.freebiesupply.com/logos/large/2x/logo-javascript-logo-black-and-white.png",
        "https://www.pngkey.com/png/full/109-1090674_php-logo-png-php-logo-png-white.png",
        "https://cdn.freebiesupply.com/logos/large/2x/nodejs-icon-logo-black-and-white.png",

        "http://laravelprogramuotojas.lt/wp-content/uploads/2022/05/1200px-Laravel-white.png",
        "https://www.loopple.com/img/tailwind-logo.png",
        "https://cdn.freebiesupply.com/logos/large/2x/mysql-logo-black-and-white.png",

        "https://uploads-ssl.webflow.com/63c6b9bb4a1e0a2b44c1b97e/63c6b9bb4a1e0a6463c1bdab_partner_logo_terraform.png",
        "https://midwestcommunityday.com/wp-content/uploads/2019/04/awslogo.png",
        "/assets/images/icons/jetbrain.png",

        "https://companieslogo.com/img/orig/OVH.PA.D-c673e287.png?t=1634541741",
        "https://git-scm.com/images/logos/downloads/Git-Icon-White.png",
        "https://samhorn.com/wp-content/uploads/2021/06/oracle-logo-large-white-1.png",
      ],
      hover: false,
    };
  },
  watch: {
    selected() {
      this.animateScale();
    }
  }
}
</script>
<style>

</style>