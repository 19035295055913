import { createRouter, createWebHistory } from 'vue-router'

import RootPage from '../views/RootPage.vue'
const routes = [
    {
        path: '/',
        name: 'RootPage',
        component: RootPage,
    },
]

export default createRouter({
    history: createWebHistory(),
    routes
})