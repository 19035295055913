<template>
  <div class="bg-black min-h-screen pt-32 hidden">
    <div class="flex flex-col items-center">
      <div>
        <p class="animate-fade text-6xl md:text-8xl lg:text-9xl select-none font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 select-none">
          Silvio Brancati
        </p>
      </div>
      <div class="wrapper">
        <p class="text-3xl md:text-4xl text">
          <span class="text-white"> développeur </span>
          <span
              class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600 select-none">Freelance</span>
        </p>
      </div>

      <div class="hidden md:pl-1 -pl-1 pt-5 cursor-pointer">
        <a target="_blank" href="https://www.linkedin.com/in/silvio-brancati-82970219b/">
          <lottie-animation class="filter invert" path="../assets/images/icons/linkedin.json"
                            :loopDelayMin="2000" :loopDelayMax="2000" :autoPlay="true" :loop="true"
                            :width="60" :height="60" style="margin: 0 0;"/>
        </a>
      </div>
    </div>
    <div class="h-full px-2 pt-44">
      <div class="grid grid-cols-2 w-full relative h-[300px]">
        <div class="cursor-pointer bg-pink-500 rounded-3xl w-full h-full rounded-[70px] grid items-stretch">
          <div class="">
            <p class="text-white font-bold text-3xl">Mon travail</p>
          </div>
          <div class="grid grid-cols-2">
            <p class="text-white font-bold bottom-0 absolute pb-5 pl-5 text-3xl">Mon travail</p>
          </div>
        </div>
        <div class="grid grid-rows-2 pl-2 gap-y-2">
          <div class="cursor-pointer bg-white w-full h-full rounded-[70px]">
            qsdqsd
          </div>
          <div class="cursor-pointer bg-[#FFF380] w-full h-full rounded-[70px]">
            qsdqsd
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-black min-h-screen">
    <!-- Name -->
    <div class="flex items-center flex-col justify-center min-h-[50vh] text-center">
      <div>
        <p class="font-clash animate-fade text-7xl md:text-8xl lg:text-9xl select-none font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 select-none">
          Silvio Brancati
        </p>
      </div>
      <div class="wrapper">
        <p class="text-3xl md:text-4xl text">
          <span class="text-white"> développeur </span>
          <span
              class="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-red-600 select-none">Freelance</span>
        </p>
      </div>
    </div>

    <!-- 3 cards in bottom of the page -->
    <div class="grid grid-cols-1 md:grid-cols-4 grid-rows-2 absolute bottom-0 w-full h-1/2 gap-x-2 pb-2 px-2 gap-y-2">
      <div onclick="window.location = '/#about'" id="box_1" class="box col-span-2 row-span-2 bg-orange-500 rounded-[70px] flex items-end cursor-pointer hover:scale-95 hover:bg-orange-600 transition-all duration-500">
        <div class="self-center md:self-auto md:p-8 px-12 flex flex-row justify-between w-full items-center">
          <div>
            <p class="font-bold font-clash md:block hidden md:text-6xl">À propos de moi</p>
            <p class="font-bold font-clash block md:hidden text-3xl">À propos</p>
            <p class="hidden font-clash md:text-2xl md:block">Mon parcours et mes compétences.</p>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
            </svg>
          </div>
        </div>
      </div>
      <div onclick="window.location = '/#work'" id="box_2" class="box row-span-1 col-span-2 bg-white rounded-[70px] flex items-end cursor-pointer hover:scale-95 hover:bg-gray-100 transition-all duration-500">
        <div class="p-8 px-12 flex flex-row justify-between w-full items-center">
          <div>
            <p class="font-bold font-clash text-3xl md:text-6xl">Mon travail</p>
            <p class="text-2xl font-clash hidden md:block">Mes projets réalisés.</p>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
          </div>
        </div>
      </div>
      <div onclick="window.location = 'mailto:ssilvio.brancati@gmail.com'" id="box_3" class="box row-span-1 col-span-2 bg-gray-700 rounded-[70px] flex items-end cursor-pointer hover:scale-95 hover:bg-gray-800 transition-all duration-500">
        <div class="p-8 px-12 flex flex-row justify-between w-full items-center">
          <div>
            <p class="font-bold text-3xl font-clash md:text-6xl text-white">Me contacter</p>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-white">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
            </svg>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

export default {
  name: "HomePage",
  components: {
    LottieAnimation
  },
  methods: {
    scrollDown() {
      window.scrollBy(0, window.innerHeight);
    }
  }
}
</script>

<style>
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight1 {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight2 {
  0% {
    transform: translateX(125%);
  }
  100% {
    transform: translateX(0);
  }
}


/**
1st card come from left
 */
#box_1 {
  animation: slideInLeft 1.5s ease-in-out;
}

#box_2 {
  animation: slideInRight1 2.5s ease-in-out;
}

#box_3 {
  animation: slideInRight2 1.5s ease-in-out;
}

html {
  overflow-x: hidden;
}


</style>